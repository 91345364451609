<template>
  <v-container id="dashboard-view" fluid tag="section">
       <v-row>
      <v-col
      >
      <iframe :width="iframe.width" :height="iframe.height" src="https://lookerstudio.google.com/embed/reporting/3202ce8c-4e59-44dd-a585-be180bffd052/page/HSbKD" frameborder="0" style="border:0" allowfullscreen></iframe></v-col>
    </v-row>
  </v-container>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";
import Vue from "vue";

const lineSmooth = Vue.chartist.Interpolation.cardinal({
  tension: 0,
});

export default {
  name: "DashboardView",

  data: () => ({
    iframe:{
      width:"100%",
      height:0.95*window.innerHeight,
    },
    charts: [
      {
        type: "Bar",
        color: "primary",
        title: "Learners Assessment Scores ",
        subtitle: "by School",
        time: "2021-2022",
        data: {
          labels: [
            "Abat Elementary School",
            "Agmanaphao Elementary School",
            "Amiroy Elementary School",
            "Armada-Pelaez Memorial Elementary School",
            "Badiangan Elementary School",
            "Cabalabaguan Elementary School",
            "Dala-Singay Elementary School",
            "Janipa-an Elementary School",
            "Mina Central School",
            "Nasirum Elementary School",
            "Tipolo Elementary School",
            "Yugot Elementary School",
          ],
          series: [
            [179, 87, 120, 116, 165, 212, 134, 75, 667, 88, 112, 85],
            [55, 36, 28, 42, 66, 67, 51, 20, 187, 40, 50, 28],
          ],
        },
        options: {
          axisX: {
            showGrid: true,
          },
          low: 0,
          high: 200,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 100%)",
            {
              seriesBarDistance: 10,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
      {
        type: "Bar",
        color: "success",
        title: "Learners Assessment Scores",
        subtitle:
          'by Grade Level',
        time: "2021-2022",
        data: {
          labels: ["Grade 2", "Grade 3", "Grade 4", "Grade 5", "Grade 6"],
          series: [[415, 466, 365, 475, 319],[142,195,131,137,65]],
        },
        options: {
          lineSmooth,
          low: 0,
          high: 500, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      },
      // {
      //   type: "Line",
      //   color: "info",
      //   title: "Completed Tasks",
      //   subtitle: "Last Campaign Performance",
      //   time: "campaign sent 26 minutes ago",
      //   data: {
      //     labels: ["M", "T", "W", "T", "F", "S", "S"],
      //     series: [[12, 17, 7, 17, 23, 18, 38]],
      //   },
      //   options: {
      //     lineSmooth,
      //     low: 0,
      //     high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      //     chartPadding: {
      //       top: 0,
      //       right: 0,
      //       bottom: 0,
      //       left: 0,
      //     },
      //   },
      // },
    ],
    headers: [
      {
        sortable: false,
        text: "ID",
        value: "id",
      },
      {
        sortable: false,
        text: "Name",
        value: "name",
      },
      {
        sortable: false,
        text: "Salary",
        value: "salary",
        align: "right",
      },
      {
        sortable: false,
        text: "Country",
        value: "country",
        align: "right",
      },
      {
        sortable: false,
        text: "City",
        value: "city",
        align: "right",
      },
    ],
    items: [
      {
        id: 1,
        name: "Dakota Rice",
        country: "Niger",
        city: "Oud-Tunrhout",
        salary: "$35,738",
      },
      {
        id: 2,
        name: "Minerva Hooper",
        country: "Curaçao",
        city: "Sinaai-Waas",
        salary: "$23,738",
      },
      {
        id: 3,
        name: "Sage Rodriguez",
        country: "Netherlands",
        city: "Overland Park",
        salary: "$56,142",
      },
      {
        id: 4,
        name: "Philip Chanley",
        country: "Korea, South",
        city: "Gloucester",
        salary: "$38,735",
      },
      {
        id: 5,
        name: "Doris Greene",
        country: "Malawi",
        city: "Feldkirchen in Kārnten",
        salary: "$63,542",
      },
    ],
    stats: [
      {
        actionIcon: "mdi-alert",
        actionText: "Get More Space...",
        color: "#FD9A13",
        icon: "mdi-sofa-single",
        title: "Bookings",
        value: "184",
      },
      {
        actionIcon: "mdi-tag",
        actionText: "Tracked from Google Analytics",
        color: "primary",
        icon: "mdi-chart-bar",
        title: "Website Visits",
        value: "75.521",
      },
      {
        actionIcon: "mdi-calendar-range",
        actionText: "Last 24 Hours",
        color: "success",
        icon: "mdi-store",
        title: "Revenue",
        value: "$34,245",
      },
      {
        actionIcon: "mdi-history",
        actionText: "Just Updated",
        color: "info",
        icon: "mdi-twitter",
        title: "Followers",
        value: "+245",
      },
    ],
    tabs: 0,
    tasks: {
      0: [
        {
          text: 'Sign contract for "What are conference organizers afraid of?"',
          value: true,
        },
        {
          text: "Lines From Great Russian Literature? Or E-mails From My Boss?",
          value: false,
        },
        {
          text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
          value: false,
        },
        {
          text: "Create 4 Invisible User Experiences you Never Knew About",
          value: true,
        },
      ],
      1: [
        {
          text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
          value: true,
        },
        {
          text: 'Sign contract for "What are conference organizers afraid of?"',
          value: false,
        },
      ],
      2: [
        {
          text: "Lines From Great Russian Literature? Or E-mails From My Boss?",
          value: false,
        },
        {
          text: "Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit",
          value: true,
        },
        {
          text: 'Sign contract for "What are conference organizers afraid of?"',
          value: true,
        },
      ],
    },
  }),

  computed: {
    sales: get("sales/sales"),
    totalSales() {
      return this.sales.reduce((acc, val) => acc + val.salesInM, 0);
    },
  },
};
</script>
